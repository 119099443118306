<template>
    <section class="d-flex search-overlay">
        <div class="fragment-container">
            <arc-search
                :globalSearchText = "globalSearchText"
                :invokeSearch = "invokeSearch"
                @show-asset-versions="onShowAssetVersions"
                @search-completed="onSearchCompleted"
            />
        </div>
    </section>
</template>
<script>
import { AppStateStore } from "@/stores/appState";
import { mapState } from "pinia";
import { setLogLevel } from "@azure/logger";

setLogLevel("info");

export default {
    name: "SearchResults",
    friendlyName: "Search",
    data() {
        return {
            invokeSearch: true
        };
    },
    computed: {
        ...mapState(AppStateStore, [
            "globalSearchText",
            "invokeGlobalSearch"
        ])
    },
    watch: {
        invokeGlobalSearch() {
            const self = this;
            self.invokeSearch = (this.globalSearchText.length > 2);
        }
    },
    methods: {        
        onShowAssetVersions(e) {
            this.$router.push({ name: "search:versions", params: { assetId: e.detail.assetId }});
        },
        onSearchCompleted(e) {
            const self = this;
            self.invokeSearch = false;
        }
    }
}
</script>